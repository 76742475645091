/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import ReactGA from "react-ga"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"
import { Container } from "semantic-ui-react"

import Header from "./header"
import "./layout.css"

class Layout extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-128492818-1")
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-128492818-1"
              />
              <script
                defer
                src="https://cdn.jsdelivr.net/npm/jquery@3.3.1/dist/jquery.min.js"
              />
              <link
                rel="stylesheet"
                href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/tiny-slider.css"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Montserrat|Open+Sans"
                rel="stylesheet"
              />
              <script
                defer
                src="https://use.fontawesome.com/releases/v5.8.1/js/all.js"
                integrity="sha384-g5uSoOSBd7KkhAMlnQILrecXvzst9TdC09/VM+pjDTCM+1il8RHz5fKANTFFb+gQ"
                crossorigin="anonymous"
              />
              <script
                defer
                src="https://cdn.jsdelivr.net/npm/fomantic-ui@2.7.4/dist/semantic.min.js"
              />
            </Helmet>
            <Header />
            <Container fluid textAlign="center">
              <main>{this.props.children}</main>
            </Container>
            <Container textAlign="center" style={{ marginTop: "2em" }}>
              <footer>
                <p>© LifeWorks Inc. 2018-2022</p>
              </footer>
            </Container>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
