import React, { Component } from "react"
import "./icon.css"

class Icon extends Component {
  renderIcon = () => {
    switch (this.props.icon) {
      case "globe":
        return <i className="fas fa-globe-americas" />
      case "student":
        return <i className="fas fa-user-graduate" />
      case "people":
        return <i className="fas fa-users" />
      case "heart":
        return <i className="fas fa-heart" />
      case "checkbox":
        return <i className="fas fa-check-square" />
      case "child":
        return <i className="fas fa-child" />
      case "building":
        return <i className="fas fa-building" />
      case "stopwatch":
        return <i className="fas fa-stopwatch" />
      case "arrow":
        return <i className="far fa-arrow-alt-circle-up" />
      case "unitedstates":
        return (
          <img
            src="https://res.cloudinary.com/mystay-international/image/upload/v1555303618/ISWP-CHINA/united-kingdom.svg"
            alt="United States Icon"
            class="language-icon"
          />
        )
      case "china":
        return (
          <img
            src="https://res.cloudinary.com/mystay-international/image/upload/v1555303616/ISWP-CHINA/china.svg"
            alt="China Icon"
            class="language-icon"
          />
        )
    }
  }

  render() {
    return <>{this.renderIcon()}</>
  }
}

export default Icon
