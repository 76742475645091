import { Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import Language from "./language"
import { Container } from "semantic-ui-react"
import styled from "styled-components"

const StyledHeader = styled.header`
  padding: 1em 1em;
  margin-bottom: 3em;
  background: white;
  box-shadow: 0px 1px 9px 1px #d6d6d6;
`

const Header = () => (
  <StyledHeader style={{ paddingTop: "1em" }}>
    <Container>
      <h1 style={{ margin: 0, display: "inline-block" }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img
            src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,h_100/v1533185467/ISWP-CHINA/iswp-alternate-logo.png"
            alt="My ISWP"
          />
        </Link>
      </h1>
      <div
        style={{
          float: `right`,
        }}
      >
        <Language />
      </div>
    </Container>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
