import React, { Component } from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { Grid } from "semantic-ui-react"
import styled from "styled-components"
import Icon from "./icon"

const StyledLink = styled.a`
  margin: auto;
  cursor: pointer;
  color: black;
`

const IconContainer = styled.span`
  .language-icon {
    font-size: 1em;
  }
`

const languageName = {
  en: "English",
  zh: "Chinese",
}

class Language extends Component {
  renderIcon = language => {
    if (language === "en") {
      return <Icon icon="unitedstates" className="language-icon" />
    } else {
      return <Icon icon="china" />
    }
  }

  render() {
    return (
      <Grid columns="2">
        <Grid.Row>
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.map(language => (
                <Grid.Column>
                  <StyledLink
                    key={language}
                    onClick={() => changeLocale(language)}
                  >
                    <IconContainer>{this.renderIcon(language)}</IconContainer>
                    {languageName[language]}
                  </StyledLink>
                </Grid.Column>
              ))
            }
          </IntlContextConsumer>
        </Grid.Row>
      </Grid>
    )
  }
}

export default Language
